<template>
  <a-card title="任务类型分布">
    <a-watermark :content="user_id">
      <div>
        <h3>A1 数据</h3>
        <div>
          生成数量： {{ a1_generate }}
        </div>
        <div>
          下载数量： {{ a1_dowload }} 
        </div>
        <div>
          满意率: {{ (a1_dowload * 100 / a1_generate).toFixed(2) }} 
        </div>
      </div>
       <a-table 
      class="ant-table-striped"
      :row-key="record => record.id"
      :data-source="dataSource" 
      :columns="columns" 
      :pagination="false"
      :loading="loading"
      @change="handleTableChange"
      :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
        <template #style_id="{ record }">
          {{record.style_id}}
          <a-tag v-if="record.is_a1" color="red" >A1</a-tag>
        </template>
        <template #style_image="{ record }">
          <a-image  v-if="record.style_image"
            :src="record.style_image" style="width:200px;"
          />
        </template>
        <template #download_ratio="{record}">
          <div>
            {{ (record.download_total / record.total*100).toFixed(2) }}%
          </div>
        </template>
      </a-table>
    </a-watermark>
  </a-card>

  <!-- <UserEditor v-model:visible="userEditorVisible" @cancle="userEditorVisible=false" /> -->

</template>
<script>
import { defineComponent,  onMounted} from 'vue'
// import UserEditor from '@/components/UserEditor.vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import axios from 'axios'
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'

// 0排队中 1创作中 2创作完成 3创作失败
const TaskStatus = {
  0: "排队中",
  1: "创作中",
  2: "创作完成",
  3: "创作失败",
}
export default defineComponent({
  components: {
    // UserEditor,
    // SearchOutlined
  },
  data(){
    return {
      loading: false,
      dataSource: [],
      a1_dowload: 0,
      a1_generate: 0,
      pagination:{
        // total: 1,
        current: 1,
        pageSize: 99999,
      },
      noticeMessage: '',
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    user_id : () => {
      const store = useStore()
      if (store) {
        return store.state.user.user_id
      }
      
    },
  },
  methods: {
    thumbnail(urkey){
      return 'http://xuantupro.oss-cn-shanghai.aliyuncs.com/' + urkey + '?x-oss-process=image/auto-orient,1/resize,m_lfit,w_200/quality,q_90'
    },
    rawImage(urkey){
      return 'http://xuantupro.oss-cn-shanghai.aliyuncs.com/' + urkey
    },
    async handleTableChange () {
      this.loading = true
      const rsp = await request.get('/api/creation/stats/styles', {})
      this.loading = false
      this.dataSource = [].concat(rsp.data.styles)
      for(let v of rsp.data.styles) {
        if (v['is_a1']) {
          if (v['download_total']) {
            this.a1_dowload += v['download_total']
          }
          if (v['total']){
            this.a1_generate += v['total']
          }
          
          
        }
      }
    },
    
  },
  setup(props, context) {
   
    return {
      TaskStatus,
      uploadInput: null,
      columns: [
      
        {
          title: '创作类型',
          dataIndex: 'create_kind',
          key: 'create_kind',
        },
        {
          title: '风格化',
          dataIndex: 'style_title',
          key: 'style_title',
        },
        {
          title: '风格化 ID',
          dataIndex: 'style_id',
          key: 'style_id',
          slots: { customRender: 'style_id' },
        },
        {
          title: '数量',
          dataIndex: 'total',
          key: 'total',
        },
        {
          title: '下载数量',
          dataIndex: 'download_total',
          key: 'download_total',
        },
        {
          title: '下载率',
          dataIndex: 'download_ratio',
          key: 'download_ratio',
          slots: { customRender: 'download_ratio' },
        },
        {
          title: '封面',
          dataIndex: 'style_image',
          key: 'style_image',
          slots: { customRender: 'style_image' },
        },
       
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>