<template>
  <a-layout-header>
     <div style="padding: 20px; font-size: 20px;font-weight: bold;">
          Xuantu Admin
      </div>
  </a-layout-header>
    <a-layout-content>
      <div style="max-width: 500px; margin: 100px auto;">
        <img src="https://xuantupro.oss-cn-shanghai.aliyuncs.com/static/icons/appicon.jpg" style="display: block; width: 200px; height: 200px; margin: 20px auto; background: #EEE; border-radius: 8px;;" />
        <a-card title="系统登录" >
          <a-form :model="formState" @submit="onSubmit" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-item label="手机号">
              <a-input name="phone" required v-model:value="formState.phone" />
            </a-form-item>
            <a-form-item label="验证码">
              <a-input name="vcode" required v-model:value="formState.vcode" style="width:calc( 100% - 110px )" />
              <a-button block html-type="submit" style="width:100px;margin-left:10px" :disabled="countDown > 0" @click="getLoginPhoneCode">
                {{ countDown > 0 ? `${countDown} 秒` : '获取验证码' }}
              </a-button>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" html-type="submit" >登录</a-button>
            </a-form-item>
          </a-form>
        </a-card>
        </div>
  </a-layout-content>
  <a-layout-footer style="position: fixed;bottom: 0;width: 100%;text-align: center;">2024©copyright Xuantu</a-layout-footer>
</template>
<script lang="ts">
import { defineComponent, reactive, toRaw, ref } from 'vue';
import type { UnwrapRef } from 'vue';
import request from '@/utils/request'
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import { message } from 'ant-design-vue';
import md5 from 'js-md5'
import { isMobile } from '@/utils/client'

interface FormState {
  phone: string;
  // mobile?: string;
  vcode?: string;
}
export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
   
    const showPhone = ref(false)
    const countDown = ref(0)
    const timer: any = ref(null)

    const formState: UnwrapRef<FormState> = reactive({
      phone: '',
      // mobile: '',
      vcode: '',
    });
    const store = useStore()
    if (store.state.user && store.state.user.user_id != '') {
      router.push({
        path: "/home"
      })
      return
    }
    const getLoginPhoneCode = () => {
      startCountDown()
      request.post('/api/account/vcode', {
        phone: formState.phone,
      }).catch(e => {
        message.error(e)
      })
    }
    const startCountDown = () => {
      // 如果倒计时没有在进行中
      if (countDown.value === 0) {
          countDown.value = 60; // 设置初始倒计时时间
          // countDown.value = 10; // 设置初始倒计时时间

          // 启动定时器，每秒更新倒计时
          timer.value = setInterval(() => {
          if (countDown.value > 0) {
              countDown.value--;
          } else {
              // 倒计时结束，清除定时器
              clearInterval(timer.value);
              timer.value = null;
          }
          }, 1000);
      }
    }
    const onSubmit = async () => {
      const ret: any = await request.post('/api/account/login', {
        phone: formState.phone,
        code: formState.vcode,
      })
      if (ret.data && ret.data.user_id) {
        store.commit("setLogin", ret.data)
        router.push({
          path: "/home"
        })
      }
    };
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formState,
      showPhone,
      onSubmit,
      countDown,
      getLoginPhoneCode,
    };
  },
});
</script>