

const UserRoleMap = {
  0: '所有',
  1: '运营',
  2: '商务主管',
  3: '商务',
  4: '博主',
  5: '审核',
  6: '代发',
  7: '财务',
}


const UserRoleOptions =[]
for (let k in UserRoleMap) {
  UserRoleOptions.push({
    value: k,
    text: UserRoleMap[k],
  })
}

const UserRoleALL = 0
const UserRoleOperator = 1
const UserRoleDirectorManager = 2
const UserRoleDirector = 3
const UserRoleBlogger = 4
const UserRoleAuditor = 5
const UserRoleDaifa = 6
const UserRoleCaiwu = 7

const KeywordStatusMap = {
  '0': '待审核',
  '1': '已通过',
  '3': '已发布',
  '2': '未通过',
  '4': '已失效',
}

const UserStatusMap = {
  '-1': '未激活',
  '0': '待审核',
  '1': '已启用',
  '2': '已拒绝',
  '3': '已冻结',
}

const PlatformEnum = [
  '七猫','熊猫小说', 
  '夸克', '夸克搜索动漫', 
  '夸克影视综',
  '夸克网盘', 
  'QQ阅读', '书旗', '起点',
  '咚漫',
  "追书神器", 
  '小红书',
  'UC拉新', 
  'UC付费',  
  'UC网盘',
  'UC动漫',
  'UC影漫综',
  'UC',
  'UC网址分享',
  '迅雷网盘',
  '迅雷网盘链接',
  '百度', 
  '快看',
  '抖音故事',
  '抖音故事锚点',
  '头条极速版网盘',
  '悟空网盘','悟空搜索',
  '河马剧场',
  '手机淘宝',
  '哔哩哔哩',
  '迅雷小说',
  'AIPPT',
]

const PlatformForApplyEnum = [
  '七猫','熊猫小说', 
  '夸克','夸克搜索动漫', '夸克影视综', 
  '夸克网盘', 
  'QQ阅读', '书旗', '起点',
  '咚漫',
  // "追书神器", 
  // '小红书',
  // 'UC拉新', 
  'UC付费',  
  'UC网盘',
  'UC网址分享',
  // 'UC动漫',
  // 'UC影漫综',
  // 'UC',
  '迅雷网盘',
  '迅雷网盘链接',
   // '百度', 
  // '快看'
  '抖音故事',
  '抖音故事锚点',
  '头条极速版网盘',
  '悟空网盘','悟空搜索',
  '河马剧场',
  '手机淘宝',
  '哔哩哔哩',
  '迅雷小说',
  'AIPPT',
  
]


const UseBooks = {
  "七猫": true,
  "熊猫小说": true,
  "追书神器": true,
  "UC拉新": true,
  "UC付费": true,
  "快看": true,
  "抖音故事": true,
  '河马剧场': true,
  '迅雷小说': true,
}

const ContentCategory = [
  // 小说、软件教程、头像壁纸、绘画、原创壁纸、动漫、漫画、影视
  { value: '小说', },
  { value: '软件教程', },
  { value: '头像壁纸', },
  { value: '绘画', },
  { value: '原创壁纸', },
  { value: '动漫', },
  { value: '漫画', },
  { value: '影视', },
]


const ContentCategoryForWukong = [
  { value: '电影', },
  { value: '电视剧', },
  { value: '动漫漫画', },
]

const ContentCategoryForWukongPlatform = [
  { value: '抖音', },
  { value: '快手', },
  { value: '其他', },
]

const ContentCategoryForWukongZhandian = [
  { value: '新片吧', },
  // { value: '漫岛动漫', },
  { value: '全集网', },
  // { value: '80dvd影院', },
  { value: '音范丝', },
  // { value: 'Cokemv', },
  { value: '爱看机器人', },
  { value: '哈哩哈哩', },
  { value: '嘀哩嘀哩', },
  { value: '学之翼影视', },
  { value: '看戏网', },
  { value: '80s手机电影', },
  // { value: '70看看', },
  { value: '剧集屋', },
  { value: 'VS影院', },
  { value: '蚂蚁影视', },
  { value: '樱花动漫', },
  { value: '十品影视', },
  // { value: '星空影视', },
  // { value: '胖虎影视', },
  // { value: '星空影院', },
]

const ContentCategoryForUCDisk = [
  {
    value: '小说',
  },
  {
    value: '软件教程',
  },
  {
    value: '头像壁纸',
  },
  {
    value: '绘画',
  },
  {
    value: '原创壁纸',
  },
  {
    value: '动漫',
  },{
    value: '漫画',
  },{
    value: '影视',
  }
]

const ContentCategoryForBiliBili = [
  {
    value: '软件数码',
  },{
    value: '游戏福利',
  },{
    value: '娱乐私域',
  },
  {
    value: '教育学习',
  },
  {
    value: '纪录片',
  },
]
const ContentCategoryForUCDiskOnly = [
  {
    value: '小说',
  },
  {
    value: '软件教程',
  },
  {
    value: '头像壁纸',
  },
  {
    value: '绘画',
  },
  {
    value: '原创壁纸',
  },
  {
    value: '动漫',
  },{
    value: '漫画',
  },{
    value: '影视',
  },{
    value: '美女',
  },{
    value: '梗图',
  },{
    value: '数码',
  },{
    value: '游戏',
  },{
    value: '双男主',
  },{
    value: '学习资料',
  },{
    value: '插件',
  },{
    value: '音乐资源包',
  }
]


const ContentCategoryQuark = [
  {
    value: '小说',
  },
  {
    value: '漫画',
  },
  {
    value: '小说书友圈',
  },
  {
    value: '小说优化书单',
  },
  {
    value: '漫画优化书单',
  },

]

// ContentCategoryForQidian 起点
const ContentCategoryForQidian = [
  {
    value: '文本书',
  },{
    value: '漫画',
  }
]

const ContentCategoryToutiaoFastPan = [
  { value: "视频-动漫",},{
    value: "视频-影视",},{
    value: "其他视频-美女瓜",},{
    value: "图片-壁纸/头像",},{
    value: "图片-漫画/绘画",},{
    value: "文本-阅读",},{
    value: "文本-其他",},{
    value: "app-游戏/教程",},{
    value: "app-非游戏",},{
    value: "音频",},{
    value: "学习-公考/考研",},{
    value: "学习-k12",},{
    value: "其他学习",},{
    value: "测试-cs"}
]

const ContentCategoryWukongFastPan = [
  { value: "视频-动漫",},{
    value: "视频-影视",},{
    value: "其他视频-美女瓜",},{
    value: "图片-壁纸/头像",},{
    value: "图片-漫画/绘画",},{
    value: "文本-小说",},{
    value: "app-游戏/教程",},{
    value: "app-非游戏",},{
    value: "音频",},{
    value: "学习-公考/考研",},{
    value: "学习-k12",},{
    value: "测试-cs"}
]
// ContentCategoryForDongMan 咚漫
const ContentCategoryForDongMan = [
  {
    value: '小说',
  },{
    value: '漫画',
  }
]

// ContentCategoryForUCYSZ UC影漫综
const ContentCategoryForUCYSZ = [
  {
    value: '综艺',
  },
  {
    value: '电影',
  },
  {
    value: '漫画',
  },
  {
    value: '电视剧',
  }
]


const _PlatformSug = []
for (const i of PlatformEnum) {
  _PlatformSug.push({
    value:i,
  })
}

const PlatformSug = [
  {
    label: "所有",
    value: "",
  },
  ..._PlatformSug,
]

const UC_INTERVENE_SC = "普通干预"
const UC_INTERVENE_NO_SC = "强制干预第一位"
const UC_INTERVENE_URL = "网页拦截"

const CREATE_RESOURCE_PACKAGE = "CREATE_RESOURCE_PACKAGE"
const payment_finance_audit = "payment_finance_audit"
const payment_finance_submit = "payment_finance_submit"

const PaymentApplyAuditStatus = [
  {
    label: "待审核",
    value: 0,
  }, {
    label: "待审核",
    value: 1,
  }, {
    label: "已通过",
    value: 2,
  }, {
    label: "已拒绝",
    value: 3,
  }
]

const PaymentApplyAuditStatusMap = {
  0: '待提交',
  1: '待审核',
  2: '已通过',
  3: '已拒绝',
}
const PaymentApplyAuditStatusColorMap = {
  0: '',
  1: 'red',
  2: 'green',
  3: 'purple',
}
const PaymentTransactionStatusMap = {
  0: '待付款',
  1: '付款单创建中',
  2: '付款单已创建',
  3: '付款成功',
  4: '付款中',
  5: '付款暂停',
  6: '付款失败',
  7: '付款部分成功',
  10: '付款提交审核',
  11: '付款审核通过',
  12: '付款审核拒绝',
}
const PaymentTransactionStatusColorMap = {
  0: '',
  1: '',
  2: '',
  3: '#87d068',
  4: '#f50',
  5: '#faad14',
  6: '#ff4d4f',
  7: 'green',
  10: 'pink',
  11: 'cyan',
  12: 'purple',
}

export  {
  PlatformEnum,
  PlatformForApplyEnum,
  UserRoleMap,
  PlatformSug,
  KeywordStatusMap,

  UseBooks,
  ContentCategory,
  ContentCategoryForWukong,
  ContentCategoryForWukongZhandian,
  ContentCategoryForWukongPlatform,
  ContentCategoryForUCDisk,
  ContentCategoryForUCDiskOnly,
  ContentCategoryForBiliBili,
  ContentCategoryForDongMan,
  ContentCategoryForQidian,
  ContentCategoryForUCYSZ,
  ContentCategoryQuark,
  ContentCategoryToutiaoFastPan,
  ContentCategoryWukongFastPan, 
  

  UC_INTERVENE_SC,
  UC_INTERVENE_NO_SC,
  UC_INTERVENE_URL,

  UserRoleALL,
  UserRoleOperator,
  UserRoleDirectorManager,
  UserRoleDirector,
  UserRoleBlogger,
  UserRoleAuditor,
  UserRoleOptions,
  UserStatusMap,
  UserRoleDaifa,
  UserRoleCaiwu,

  CREATE_RESOURCE_PACKAGE,
  payment_finance_audit,
  payment_finance_submit,

  PaymentApplyAuditStatus,

  PaymentApplyAuditStatusMap,
  PaymentApplyAuditStatusColorMap,

  PaymentTransactionStatusMap,
  PaymentTransactionStatusColorMap,
}


// 
// 
// 
// 
// 
// 
// 插件（pc端）
// 