import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AbountView from '../views/AboutView.vue'
import Login from '@/views/Login.vue'
import UserManage from '@/views/UserManage.vue'
import CreationWorkManage from '@/views/CreationWorkManage.vue'
import StatsCreationStyle  from '@/views/StatsCreationStyle.vue'
import VIPManage from '@/views/VIPManage.vue'
import CreationSkill from '@/views/CreationSkill.vue'
import BanManage from '@/views/BanManage.vue'
import UserStats from '@/views/UserStats.vue'
import SensiWordVue from '@/views/SensiWord.vue'

const routes: Array<RouteRecordRaw> = [
 
  {
    path: '/',
    alias: '/home',
    name: '',
    component: HomeView,
    children: [
      {
        path: 'about',
        name: 'about',
        component:AbountView
      },
      {
        path: 'user',
        name: 'user',
        component: UserManage
      },
      {
        path: 'vips',
        name: 'vips',
        component: VIPManage
      },
      {
        path: 'creation/works',
        name: 'creation/works',
        component: CreationWorkManage
      },
      {
        path: 'creation/stats/style',
        name: 'creation/stats/style',
        component: StatsCreationStyle
      },
      {
        path: 'creation/sskills',
        name: 'creation/sskills',
        component: CreationSkill
      },
      {
        path: 'user/ban_manage',
        name: 'user/ban_manage',
        component: BanManage
      },
      {
        path: 'anti/sensiword',
        name: 'anti/sensiword',
        component: SensiWordVue
      },
      {
        path: 'user/stats',
        name: 'user/stats',
        component: UserStats,
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
