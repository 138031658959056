<template>
  <a-card title="用户封禁">
    <a-space >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item>
          <a-input placeholder="用户 ID" v-model:value="formSearch.user_id" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handelOpenDia()"
          >
          增加封禁
          </a-button>
        </a-form-item>
      </a-form>
    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />
    <a-watermark :content="nickname">
      <a-table 
      class="ant-table-striped"
      :row-key="record => record.id"
      :data-source="dataSource" 
      :columns="columns" 
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
        <template #nickname="{ record }">
          <span>{{ record.nickname ? record.nickname : '-' }}</span>
        </template>
        <template #is_vip="{ record }">
          <a-tag v-if="record.is_vip" color="red">VIP</a-tag>
        </template>
        <template #ban_type="{ record }">
          {{ banTypesName[record.ban_type] }}
        </template>
        <template #expired_at="{ record }">
          {{ record.expired_at }}
          <a-tag v-if="record.is_expired" color="red">EXPIRED</a-tag>
        </template>
        <template #action="{ record }">
          <a-space>
            <a-button @click="handelRelease(record)"  danger >解除</a-button>
          </a-space>
        </template>
      </a-table>
    </a-watermark>
  </a-card>
  <a-modal v-model:visible="openDia" :title="'用户封禁'" @cancel="resetForm" @ok="handleConfirm" :confirm-loading="confirmLoading">
    <a-form :model="formAdd" style="margin: 10px auto;" :rules="rules" ref="formRef" >
        <a-form-item label="用户 ID" name="user_id">
          <a-input placeholder="用户 ID" v-model:value="formAdd.user_id" />
        </a-form-item>
        <a-form-item label="封禁类型" name="ban_type">
          <a-select
            v-model:value="formAdd.ban_type"
            :options="banTypes"
            placeholder="封禁类型"
          />
        </a-form-item>
        <a-form-item  label="封禁至"  name="days">
          <a-date-picker v-model:value="formAdd.expired_at" />
        </a-form-item>
        <a-form-item  label="封禁原因"  name="reason">
          <a-input placeholder="封禁原因" v-model:value="formAdd.reason" />
        </a-form-item>
      </a-form>
  </a-modal>
  <!-- <UserEditor v-model:visible="userEditorVisible" @cancle="userEditorVisible=false" /> -->

</template>
<script>
import { defineComponent,  onMounted} from 'vue'
// import UserEditor from '@/components/UserEditor.vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import axios from 'axios'
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'


const formData = {
        user_id: '',
        ban_type: 1,
        reason:"违反用户行为准则",
        days: 7,
        expired_at: null,
      }
export default defineComponent({
  components: {
    // UserEditor,
    SearchOutlined
  },
  data(){
    return {
      banTypes: [
        {
          label:"图片生成",
          value: 1,
        }
      ],
      banTypesName: {
        1: "图片生成",
      },
      loading: false,
      openDia: false,
      dataSource: [],
      formAdd: {
        ...formData
      },
      pagination: {
        total: 1,
        current: 0,
        pageSize: 20,
        'show-size-changer': true,
        pageSizeOptions: ['20', '50', '100']
      },
      noticeMessage: '',

       formSearch: {
        user_id: ''
      },

      uploadLoading: false,
    }
  },
  mounted(){
    this.handleTableChange({pageSize: 20, current: 1})
  },
  computed:{
    nickname : () => {
      const store = useStore()
      if (store) {
        return store.state.user.nickname
      }
      
    },
  },
  methods: {
    handelOpenDia(){
      this.openDia = !this.openDia
      this.$nextTick(()=>{
        this.formAdd = {
          ...formData
        }
        this.resetForm()
      })
      
    },
    async handleConfirm(){
      const rsp = await request.post('/api/users/bans', this.formAdd)
      this.openDia = !this.openDia
      this.loading = false
      this.handleTableChange()
    },
    async  handelRelease(record){
      const rsp = await request.post('/api/users/bans', {
        ...record,
        expired_at: new Date(),
        reason: "后台解除",
      })
      this.loading = false
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    },
    async handleTableChange (pagi = {pageSize: 20, current: 1}) {
      this.loading = true
      const rsp = await request.get('/api/users/bans', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        user_id: this.formSearch.user_id
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
   
    return {
      uploadInput: null,
      columns: [
      {
          title: 'UID',
          dataIndex: 'user_id',
          key: 'user_id',
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          key: 'nickname',
          slots: { customRender: 'nickname' },
        },
        {
          title: 'VIP',
          dataIndex: 'is_vip',
          key: 'is_vip',
          slots: { customRender: 'is_vip' },
        },
        {
          title: '封禁原因',
          dataIndex: 'reason',
          key: 'reason',
        },
        {
          title: '封禁类型',
          dataIndex: 'ban_type',
          key: 'ban_type',
          slots: { customRender: 'ban_type' },
        },
        {
          title: '过期时间',
          dataIndex: 'expired_at',
          key: 'expired_at',
          slots: { customRender: 'expired_at' },
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          key: 'created_at',
        },
        {
          title: '修改时间',
          dataIndex: 'updated_at',
          key: 'updated_at',
        },{
        title: '操作人',
          dataIndex: 'operator_name',
          key: 'operator_name',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          slots: { customRender: 'action' },
        },
       
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>