<template>
  <a-card title="任务管理">
    <a-space >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item>
          <a-input placeholder="用户 ID" v-model:value="formSearch.user_id" />
        </a-form-item>
        <a-form-item>
          <a-input placeholder="任务 ID" v-model:value="formSearch.task_id" />
        </a-form-item>
        <a-form-item  label="任务状态"  name="status">
          <a-select
            style="width: 100px;"
            v-model:value="formSearch.status"
            :options="statusOptions"
          />
        </a-form-item>
        <a-form-item  label="创作类型"  name="style_id">
          <a-select
            style="width: 100px;"
            v-model:value="formSearch.style_id"
            :options="styleIDOptions"
          />
        </a-form-item>
        <a-form-item  label="创作类型（自定义）" >
          <a-input placeholder="style_id" v-model:value="formSearch.style_id" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
      </a-form>
    </a-space>
    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />
    <a-watermark :content="user_id">
      <a-table 
    class="ant-table-striped"
    show-quick-jumper
    :row-key="record => record.id"
    :data-source="dataSource" 
    :columns="columns" 
    :pagination="pagination"
    :loading="loading"
    @change="handleTableChange"
    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
    >
      <template #id="{ record }">
      {{ record.id }}
        <a-tag v-if="record.is_first" color="red">First</a-tag>
      </template>
      <template #nickname="{ record }">
        <span>{{ record.nickname ? record.nickname : '-' }}</span>
        <a-tag v-if="record.is_vip" color="red">VIP</a-tag>
      </template>
      <template #base_img="{ record }">
       <div class="img-thumbnail">
          <a-image
            placehoder=false
            :preview='{src: rawImage(record.base_image_file)}'
            :src="thumbnail(record.base_image_file)"
          />
       </div>
      </template>
      <template #alter_img="{ record }">
        <div class="img-thumbnail">
          <a-image  v-if="record.alter_image_file" 
            placehoder=false
            :preview='{src: rawImage(record.alter_image_file)}'
            :src="thumbnail(record.alter_image_file)"
          />
        </div>
       
      </template>
      <template #mask_file="{ record }">
        <div class="img-thumbnail">
          <a-image  v-if="record.mask_file" 
            placehoder=false
            :preview='{src: rawImage(record.mask_file)}'
            :src="thumbnail(record.mask_file)"
          />
        </div>
      </template>
      <template #works="{ record }">
        <div class="img-thumbnail">
          <a-image-preview-group>
            <div  v-for="itm of record.works" :key="itm.id"  style="position: relative;" >
              <a-image 
               
                placehoder=false
                :preview='{src: rawImage(itm.works_url)}'
                :src="thumbnail(itm.works_url)"
              />
              <div style="display: block;   margin-top:-22px; position: relative;" >
                <a-tag color="processing" >{{ itm.download_total }}</a-tag>
              </div>
             
            </div>
          </a-image-preview-group>
        </div>
       
      
      </template>
      <template #status="{ record }">
       <div>{{ TaskStatus[record.status] }} ({{ record.reason }})</div>
      </template>
      <template #style_title="{ record }">
        {{ record.create_kind }} <br/>
        {{ record.create_tool }} <br/>
        {{ record.style_title }} <br/>

      </template>
      <template #task_start_at="{ record }">
        开始时间： {{ record.task_start_at }} <br/>
        创建时间： {{ record.created_at }}
      </template>
      <template #prompt="{ record }">
       <div>{{ record.prompts }}</div>
       <div>{{ record.trans_prompts }}</div>
      </template>
    </a-table>
    </a-watermark>
    
  </a-card>

  <!-- <UserEditor v-model:visible="userEditorVisible" @cancle="userEditorVisible=false" /> -->

</template>
<script>
import { defineComponent,  onMounted} from 'vue'
// import UserEditor from '@/components/UserEditor.vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import axios from 'axios'
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'
import {useRoute} from 'vue-router'

// 0排队中 1创作中 2创作完成 3创作失败
const TaskStatus = {
  0: "排队中",
  1: "创作中",
  2: "创作完成",
  3: "创作失败",
}
export default defineComponent({
  components: {
    // UserEditor,
    SearchOutlined
  },
  data(){
    return {
      loading: false,
      dataSource: [],
      pageNo: 0,
      styleIDOptions:  [{
          label: "所有", value: '',
        },{
          label: "AI替换", value: 0
        },
        {
          label: "AI增加", value: 1
        }, {
          label: "AI消除", value: 2
        }, {
          label: "AI抠图", value: 3
        }, {
          label: "AI换背景", value: 4
        }, {
          label: "一键高清", value: 5
        }, {
          label: "一键扩图", value: 6
        }, {
          label: "AI补光", value: 7
        }, {
          label: "背景模糊", value: 8
        }, {
          label: "照片上色", value: 9
        }, {
          label: "AI证件照", value: 10
        }
      ],
      statusOptions: [{
          label: "所有", value: '',
        },
        {
          label: "排队中", value: 0
        }, {
          label: "执行中", value: 1
        }, {
          label: "执行成功", value: 2
        }, {
          label: "执行失败", value: 3
        }, {
          label: "执行退出", value: 4
        }
      ],
      pagination: {
        total: 1,
        current: 0,
        pageSize: 20,
        'show-quick-jumper': true,
        'show-size-changer': true,
        pageSizeOptions: ['20', '50', '100']
      },
      noticeMessage: '',

       formSearch: {
        style_id: '',
        status: '',
        user_id: '',
        task_id: '',
      },

      uploadLoading: false,
    }
  },
  mounted(){
    const route = useRoute()
    if (route.query['task_id']) {
      this.formSearch.task_id = route.query['task_id']
    }

    this.handleTableChange()
  },
  computed:{
    user_id : () => {
      const store = useStore()
      if (store) {
        return store.state.user.user_id
      }
      
    }, 
    nickname : () => {
      const store = useStore()
      if (store) {
        return store.state.user.nickname
      }
      
    },
  },
  methods: {
    thumbnail(urkey){
      return 'http://xuantupro.oss-cn-shanghai.aliyuncs.com/' + urkey + '?x-oss-process=image/auto-orient,1/resize,m_lfit,w_200/quality,q_90'
    },
    rawImage(urkey){
      return 'http://xuantupro.oss-cn-shanghai.aliyuncs.com/' + urkey
    },
    async handleTableChange (pagi = {pageSize: 20, current: 1}) {
      this.loading = true
      const rsp = await request.get('/api/creation/works', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        ...this.formSearch,
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
    
  },
  setup(props, context) {
   
    return {
      TaskStatus,
      uploadInput: null,
      columns: [
      {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          slots: { customRender: 'id' },
        },
        {
          title: '用户ID',
          dataIndex: 'user_id',
          key: 'user_id',
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          key: 'nickname',
          slots: { customRender: 'nickname' },
        },
        {
          title: '原图',
          dataIndex: 'base_img',
          key: 'base_img',
          slots: { customRender: 'base_img' },
        },
        {
          title: 'Alter',
          dataIndex: 'alter_img',
          key: 'alter_img',
          slots: { customRender: 'alter_img' },
        },
        {
          title: 'Mask',
          dataIndex: 'mask_file',
          key: 'mask_file',
          slots: { customRender: 'mask_file' },
        },
        {
          title: 'Prompt',
          dataIndex: 'prompt',
          key: 'prompt',
          slots: { customRender: 'prompt' },
        },
        // {
        //   title: '作品类型',
        //   dataIndex: 'create_kind',
        //   key: 'create_kind',
        // },
        // {
        //   title: '工具类型',
        //   dataIndex: 'create_tool',
        //   key: 'create_tool',
        // },
        {
          title: '风格',
          dataIndex: 'style_title',
          key: 'style_title',
          slots: { customRender: 'style_title' },
        },
        {
          title: '作品',
          dataIndex: 'works',
          key: 'works',
          slots: { customRender: 'works' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: '删除',
          dataIndex: 'is_delete',
          key: 'is_delete',
        },
        {
          title: '耗时',
          dataIndex: 'cost',
          key: 'cost',
        },
        {
          title: '任务开始时间',
          dataIndex: 'task_start_at',
          key: 'task_start_at',
          slots: { customRender: 'task_start_at' },
        },
       
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
.img-thumbnail {
  max-height: 355px;
  max-width: 200px;
  overflow-y: scroll;
}
</style>