<template>
  <a-card title="敏感词配置">
    <a-space >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item>
          <a-input placeholder="敏感词" v-model:value="formSearch.word" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="handelOpenDia()"
          >
          增加敏感词
          </a-button>
        </a-form-item>
      </a-form>
    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />
    <a-watermark :content="nickname">
      <a-table 
      class="ant-table-striped"
      :row-key="record => record.id"
      :data-source="dataSource" 
      :columns="columns" 
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
        <template #word_type="{ record }">
          {{ addOptionNames[record.word_type] }}
        </template>
        <template #action="{ record }">
          <a-space>
            <a-button @click="handelDelete(record)"  danger >删除</a-button>
          </a-space>
        </template>
      </a-table>
    </a-watermark>
  </a-card>
  <a-modal v-model:visible="openDia" :title="'敏感词'" @cancel="resetForm" @ok="handleConfirm" :confirm-loading="confirmLoading">
    <a-form :model="formAdd" style="margin: 10px auto;" :rules="rules" ref="formRef" >
        <a-form-item label="敏感词" name="word">
          <a-input placeholder="敏感词" v-model:value="formAdd.word" />
        </a-form-item>
        <a-form-item label="敏感词类型" name="word_type">
          <a-select
            v-model:value="formAdd.ban_type"
            :options="addOptions"
            placeholder="敏感词类型"
          />
        </a-form-item>
      </a-form>
  </a-modal>
  <!-- <UserEditor v-model:visible="userEditorVisible" @cancle="userEditorVisible=false" /> -->

</template>
<script>
import { defineComponent,  onMounted} from 'vue'
// import UserEditor from '@/components/UserEditor.vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import axios from 'axios'
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'


const formData = {
  word: '',
  word_type: 0,
      }
export default defineComponent({
  components: {
    // UserEditor,
    SearchOutlined
  },
  data(){
    return {
      addOptions: [
        {
          label:"默认",
          value: 0,
        }
      ],
      addOptionNames: {
        0: "默认",
      },
      loading: false,
      openDia: false,
      dataSource: [],
      formAdd: {
        ...formData
      },
      pagination: {
        total: 1,
        current: 0,
        pageSize: 20,
        'show-size-changer': true,
        pageSizeOptions: ['20', '50', '100']
      },
      noticeMessage: '',
       formSearch: {
        word: ''
      },

      uploadLoading: false,
    }
  },
  mounted(){
    this.handleTableChange({pageSize: 20, current: 1})
  },
  computed:{
    nickname : () => {
      const store = useStore()
      if (store) {
        return store.state.user.nickname
      }
      
    },
  },
  methods: {
    handelOpenDia(){
      this.openDia = !this.openDia
      this.$nextTick(()=>{
        this.formAdd = {
          ...formData
        }
        this.resetForm()
      })
      
    },
    async handleConfirm(){
      const rsp = await request.post('/api/anti/sensiwords', this.formAdd)
      this.openDia = !this.openDia
      this.loading = false
      this.handleTableChange()
    },
    async  handelDelete(record){
      const rsp = await request.delete('/api/anti/sensiwords?word=' + record.word, {})
      this.loading = false
      this.handleTableChange()
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    },
    async handleTableChange (pagi = {pageSize: 20, current: 1}) {
      this.loading = true
      const rsp = await request.get('/api/anti/sensiwords', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        word: this.formSearch.word
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
   
    return {
      uploadInput: null,
      columns: [
       {
          title: '敏感词',
          dataIndex: 'word',
          key: 'word',
        },
        {
          title: '类型',
          dataIndex: 'word_type',
          key: 'word_type',
          slots: { customRender: 'word_type' },
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          key: 'created_at',
        },
        {
          title: '修改时间',
          dataIndex: 'updated_at',
          key: 'updated_at',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          slots: { customRender: 'action' },
        },
       
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>