import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      username: '',
      user_id: 0,
      role: 0,
    }
  },
  getters: {
  },
  mutations: {
    setLogin: (state, user) => {
      state.user = user
    } 
  },
  actions: {
  },
  modules: {
  }
})
