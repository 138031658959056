<template>
  <BasicLayout />
</template>

<script lang="ts">
import BasicLayout from '@/layouts/BasicLayout.vue'
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    BasicLayout,
  },
})
export default class Home extends Vue {}
</script>
