<template>
  <a-card title="创意技巧">
    <a-space >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <!-- <a-form-item>
          <a-input placeholder="用户 ID" v-model:value="formSearch.query" />
        </a-form-item> -->
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="openDia = !openDia"
          >
          新建技巧
          </a-button>
        </a-form-item>
      </a-form>
    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />
    <a-watermark :content="nickname">
      <a-table 
      class="ant-table-striped"
      :row-key="record => record.id"
      :data-source="dataSource" 
      :columns="columns" 
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
      <template #style_name="{ record }">
        {{ styleNames[record.style_id]}}
      </template>
      <template #cover_url="{ record }">
        <a-image
          placehoder="true"
          :src="record.cover_url" width="100"
        />
      </template>
      <template #action_image_urls="{ record }">
        <div style="display: flex; flex-direction: row;">
          <a-image
          v-for="u of record.action_image_urls"
          :key="u"
          placehoder="true"
          :src="u" 
          width="100"
        />
        </div>
      </template>
    
      <template #origin_image_url="{ record }">
        <a-image
          placehoder="true"
          :src="record.origin_image_url" width="100"
        />
      </template>
      <template #status="{ record }">
        <a-tag v-if="record.status == 1" color="green">上线</a-tag>
        <a-tag v-if="record.status == 0" color="gray">下线</a-tag>

      </template>
      <template #action="{ record }">
       <a-space>
        <a-button @click="handleModifyStatus(record)" v-if="record.status == 0" type="primary">上线</a-button>
        <a-button @click="handleModifyStatus(record)"  v-if="record.status == 1"  danger>下线</a-button>
        <a-button v-if="record.status == 0"   warn @click="handleUpdate(record)"  >编辑</a-button>
        <a-button v-if="record.status == 0"   danger @click="handleDelete(record)"  >删除</a-button>
       </a-space>
      </template>
      </a-table>
    </a-watermark>
  </a-card>
  <a-modal v-model:visible="openDia" :title="'新建技巧'" @cancel="resetForm" @ok="handleConfirm" :confirm-loading="confirmLoading">
    <a-form :model="formAdd" style="margin: 10px auto;" :rules="rules" ref="formRef" >
        <a-form-item label="标题" name="title">
          <a-input placeholder="标题" v-model:value="formAdd.title" />
        </a-form-item>
        <a-form-item  label="风格(工具)"  name="style_id">
          <a-select
            v-model:value="formAdd.style_id"
            :options="styleIDOptions"
            placeholder="产品 ID"
          />
        </a-form-item>
        <a-form-item label="原图" name="origin_image_url">
          <Upload v-model:imageUrl="formAdd.origin_image_url"  />
        </a-form-item>
        <a-form-item label="封面" name="cover_url">
          <Upload v-model:imageUrl="formAdd.cover_url"  />
        </a-form-item>
        
        <a-form-item label="操作" name="action_image_url">
          <div v-for="(item, index) in formAdd.action_image_urls " :key="index">
            <div style="display: flex; flex-direction: row; ">
              <Upload  v-model:imageUrl="formAdd.action_image_urls[index]"   />
              <a-button danger @click="delete(formAdd.action_image_urls[index])">删除</a-button>
            </div>
          </div>
          <a-button type="primary" @click="action_image_index ++; formAdd.action_image_urls[action_image_index]=''">增加操作图</a-button>

        </a-form-item>
      </a-form>
  </a-modal>
  <!-- <UserEditor v-model:visible="userEditorVisible" @cancle="userEditorVisible=false" /> -->

</template>
<script>
import { defineComponent,  onMounted} from 'vue'
// import UserEditor from '@/components/UserEditor.vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'
import Upload from './../components/Upload.vue'


export default defineComponent({
  components: {
    Upload,
    SearchOutlined
  },
  data(){
    return {
      styleIDOptions: [],
      styleNames :{},
      loading: false,
      openDia: false,
      dataSource: [],
      action_image_index: 0,
      formAdd: {
        id: 0,
        title: '',
        style_id: '0',
        cover_url : '',
        action_image_urls: {},
        origin_image_url: '',
      },
      pagination: {
        total: 1,
        current: 0,
        pageSize: 20,
        'show-size-changer': true,
        pageSizeOptions: ['20', '50', '100']
      },
      noticeMessage: '',

       formSearch: {
        query: ''
      },

      uploadLoading: false,
    }
  },
  mounted(){
    this.handleTableChange()
    this.initStyles()
  },
  computed:{
    nickname : () => {
      const store = useStore()
      if (store) {
        return store.state.user.nickname
      }
      
    },
  },
  methods: {
    initStyles(){
      request.get('/api/creation/styles', this.formAdd).then((resp) => {
        for(let v of resp.data.styles) {   
        this.styleIDOptions.push({
          label: `${v.style_name}(${v.style_title })`,
          value: v.style_id
        })
        this.styleNames[v.style_id] = v.style_name
      }
      })
     
    },
    async handleConfirm(){
      if (this.formAdd.id) {
        const rsp = await request.put(`/api/creation/skills/${this.formAdd.id}`, {
          ...this.formAdd,
          action_image_urls: Object.values(this.formAdd.action_image_urls)
        })
      } else {
        const rsp = await request.post('/api/creation/skills', {
          ...this.formAdd,
          action_image_urls: Object.values(this.formAdd.action_image_urls)
        })
      }
     
      this.openDia = !this.openDia
      this.loading = false
      this.handleTableChange()
    },
    handleUpdate(record){
      this.openDia = true
      let data = {
        ...record
      }
      data.action_image_urls = {}
      for(let k =0; k < record.action_image_urls.length; k++) {
        data.action_image_urls[k] = record.action_image_urls[k]
      }
      this.formAdd = data
    },
    async handleDelete(record){
      const rsp = await request.delete(`/api/creation/skills/${record.id}`, {
       
      })
      this.loading = false
      this.handleTableChange()
    },
    async handleModifyStatus(record){
      const rsp = await request.put(`/api/creation/skills/${record.id}`, {
        status: record.status ==1 ? 0: 1,
      })
      this.loading = false
      this.handleTableChange()
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    },
    async handleTableChange (pagi = {pageSize: 20, current: 1}) {
      this.loading = true
      const rsp = await request.get('/api/creation/skills', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        title: this.formSearch.query
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
   
    return {
      uploadInput: null,
      columns: [
      {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: '类型',
          dataIndex: 'style_name',
          key: 'style_name',
          slots: { customRender: 'style_name' },

        },
        {
          title: '标题',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: '封面图',
          dataIndex: 'cover_url',
          key: 'cover_url',
          slots: { customRender: 'cover_url' },
        },
        {
          title: '操作图',
          dataIndex: 'action_image_urls',
          key: 'action_image_urls',
          slots: { customRender: 'action_image_urls' },
        },
        {
          title: '原图',
          dataIndex: 'origin_image_url',
          key: 'origin_image_url',
          slots: { customRender: 'origin_image_url' },
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          key: 'created_at',
        },
        {
          title: '修改时间',
          dataIndex: 'updated_at',
          key: 'updated_at',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          slots: { customRender: 'action' },
        },
       
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>