<template>
  <div style="padding-bottom: 70px;">
   
    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed"
    >
      <a-menu-item key="/home/user">
        <template #icon>
          <UserOutlined /> 
        </template>
        <router-link :to="{ path: '/home/user'}">
          用户列表
        </router-link>
      </a-menu-item>
      <a-menu-item key="/home/user/ban_manage">
        <template #icon>
          <StopOutlined />
        </template>
        <router-link :to="{ path: '/home/user/ban_manage'}">
          封禁管理
        </router-link>
      </a-menu-item>
      <a-menu-item key="/home/anti/sensiword">
        <template #icon>
          <StopOutlined />
        </template>
        <router-link :to="{ path: '/home/anti/sensiword'}">
          敏感词管理
        </router-link>
      </a-menu-item>
      <a-menu-item key="/home/vips">
        <template #icon>
          <QqOutlined />
        </template>
        <router-link :to="{ path: '/home/vips'}">
          VIP管理
        </router-link>
      </a-menu-item>
      <a-menu-item key="/home/creation/works">
        <template #icon>
          <FileImageOutlined /> 
        </template>
        <router-link :to="{ path: '/home/creation/works'}">
          作品列表
        </router-link>
      </a-menu-item>
      <a-menu-item key="/home/creation/sskills">
        <template #icon>
          <FireOutlined />
        </template>
        <router-link :to="{ path: '/home/creation/sskills'}">
         创意社区
        </router-link>
      </a-menu-item>
      <a-menu-item key="/home/creation/stats/style">
        <template #icon>
          <PieChartOutlined /> 
        </template>
        <router-link :to="{ path: '/home/creation/stats/style'}">
          作品类型统计
        </router-link>
      </a-menu-item>
      <a-menu-item key="/home/user/stats" v-if="[3, 37, 6 , 430, 15].indexOf(user_id) > -1">
        <template #icon>
          <PieChartOutlined /> 
        </template>
        <router-link :to="{ path: '/home/user/stats'}">
          用户统计
        </router-link>
      </a-menu-item>
    </a-menu>
  </div>
</template>
<script >
import { defineComponent, reactive, toRefs, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import {useStore} from 'vuex'

import {
  PieChartOutlined,
  UserOutlined,
  FileImageOutlined,
  QqOutlined,
  FireOutlined,StopOutlined
} from '@ant-design/icons-vue';


export default defineComponent({
  components: {
    PieChartOutlined,
    UserOutlined,    
    FileImageOutlined,
    QqOutlined,
    FireOutlined,
    StopOutlined
  },
  computed:{
    user_id : () => {
      const store = useStore()
      if (store) {
        return store.state.user.user_id
      }
      
    }, 
  },
  watch: {
    $route: {
       handler(route,oldval){
        this.selectedKeys = [route.path]
      },
      // 深度观察监听
      deep: true
    }
  },
  setup() {
    let route = useRoute()
    const state = reactive({
      collapsed: false,
      selectedKeys: [route.path],
      openKeys: [route.meta.key],
      preOpenKeys: ['basic'],
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      },
    );
    const router = useRouter()
    watch (
        () => state.selectedKeys,
        (val, oldVal) => {
            state.selectedKeys = val
            
        }
    )
    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      state.openKeys = state.collapsed ? [] : state.preOpenKeys;
    };

    return {
      ...toRefs(state),
      toggleCollapsed,
    };
  },
});
</script>