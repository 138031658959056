<template>
  <a-card title="用户数据统计">
    <a-space >
    </a-space>
    <a-watermark :content="user_id">
      <table class="stats-table">
        <legend>整体数据</legend>
        <tr>
          <th >项目</th><th>数据</th><th>公式</th><th>说明</th>
        </tr>
        <tr>
          <td >用户总数</td><td>{{ stats_data.user_total_count }}</td><td></td><td></td>
        </tr>
        <tr>
          <td>会员总数</td><td>{{ stats_data.vip_total_count }}</td><td></td><td>
            包括应用内购买会员、后台开通会员、终身会员等所有会员
          </td>
        </tr>
        <tr>
          <td>会员开通率</td><td>{{ (stats_data.vip_total_count / stats_data.user_total_count * 100 ).toFixed(2)}}%</td>
          <td>{{ stats_data.vip_total_count }} / {{ stats_data.user_total_count}}</td>
        </tr>
        <tr>
          <td>总收入</td><td> {{ stats_data.pay_total_amount.pay_amount / 100 }}¥</td><td></td>
          <td>系统内订单收入，不包含后台开通会员、终身会员。</td>
        </tr>
        <tr>
          <td>人均付费金额</td><td>  {{ (stats_data.pay_total_amount.pay_amount / 100 / stats_data.user_total_count ).toFixed(2) }}¥ </td>
          <td> {{ stats_data.pay_total_amount.pay_amount / 100 }} / {{ stats_data.user_total_count}}</td>
          <td></td>
        </tr>
        <tr>
          <td>付费人数</td><td> {{ stats_data.pay_user_total_count  }}</td><td></td>
          <td>系统内订单收入，不包含后台开通会员、终身会员。</td>
        </tr>
        <tr>
          <td>总付费率</td><td> {{ (stats_data.pay_user_total_count / stats_data.user_total_count * 100).toFixed(2)  }}% </td>
          <td>{{ stats_data.pay_user_total_count }} / {{stats_data.user_total_count  }}</td>
          <td></td>
        </tr>
        <!-- <tr>
          <td>7日内付费用户数</td><td> {{ stats_data.pay_user_7day_count }} </td><td></td>
          <td>系统内订单收入，不包含后台开通会员、终身会员。</td>
        </tr>
        <tr>
          <td>7日内新增用户数</td><td> {{ stats_data.user_7day_count }} </td><td></td>
          <td></td>
        </tr> -->
        <!-- <tr>
          <td>7日内用户付费率</td>
          <td> {{ (stats_data.pay_user_7day_count / stats_data.user_7day_count * 100).toFixed(2) }}% </td>
          <td> {{ stats_data.pay_user_7day_count }} / {{ stats_data.user_7day_count}}  </td>
          <td>7日前用户在7日内某一天付费 
          </td>
        </tr> -->
        <tr v-for="(item, index) in stats_data.payment_user_stats" :key="index">
          <td>{{index}} 日内用户付费率</td>
          <td> {{ (item["payment_count"] / item["count"] * 100).toFixed(2) }}% </td>
          <td> {{ item["payment_count"] }} / {{ item["count"] }}  </td>
          <td>{{ index }} 日前注册用户在 {{ index }} 日内某一天付费 </td>
        </tr>
        <tr>
          <td>30日内付费用户数</td><td> {{ stats_data.pay_user_30day_count }} </td><td></td>
          <td>系统内订单收入，不包含后台开通会员、终身会员。</td>
        </tr>
        <!-- <tr>
          <td>30日内新增用户数</td><td> {{ stats_data.user_30day_count }} </td><td></td>
          <td></td>
        </tr>
        <tr>
          <td>30日内用户付费率</td>
          <td> {{ (stats_data.pay_user_30day_count / stats_data.user_30day_count * 100).toFixed(2) }}% </td>
          <td> {{ stats_data.pay_user_30day_count }} / {{ stats_data.user_30day_count}}  </td>
          <td>30日前用户在30日内某一天付费 
          </td>
        </tr> -->
        <tr>
          <td >30日续费数量</td><td>{{ stats_data.renew_order_count }}</td><td></td>
          <td>系统内订单收入，不包含后台开通会员、终身会员。</td>
        </tr>
        <tr>
          <td >30日会员已过期数量</td><td>{{ stats_data.vip_expire_count }}</td><td></td>
          <td> 包括应用内购买会员、后台开通会员、终身会员等所有会员</td>
        </tr>
        <tr>
          <td >30日续费率</td><td>
            {{ (stats_data.renew_order_count / (stats_data.vip_expire_count + stats_data.renew_order_count) * 100).toFixed(2)  }}%
          </td><td></td>
          <td> 30日续费数量 / (30日续费数量 + 30日会员已过期数量)</td>
        </tr>
        <tr>
          <td>订单信息</td>
          <td> Apple {{ stats_data.total_order_sum.apple.pay_amount / 100 }}¥  (count: {{ stats_data.total_order_sum.apple.total_count }})<br/>
          Alipay  {{ stats_data.total_order_sum.alipay.pay_amount / 100 }}¥ (count: {{ stats_data.total_order_sum.alipay.total_count }}) </td>
          <td></td>
          <td>系统内订单收入，不包含后台开通会员、终身会员</td>
        </tr>
      </table>
      <hr />
      <table class="stats-table">
        <legend>整体分端数据</legend>
        <tr>
          <th >项目</th><th>iOS</th><th>Android</th><th>说明</th>
        </tr>
        <tr>
          <td >用户总数</td>
          <td>{{ stats_data.user_totals.ios }}</td>
          <td>{{ stats_data.user_totals.android }}</td>
          <td></td>
        </tr>
        <tr>
          <td >付费总额</td>
          <td>{{ stats_data.payment_sums.ios.pay_amount / 100 }}¥</td>
          <td>{{ stats_data.payment_sums.android.pay_amount  / 100 }}¥</td><td>
            系统内订单收入，不包含后台开通会员、终身会员。
          </td>
        </tr>
        <tr>
          <td >付费用户总数</td>
          <td>{{ stats_data.payment_user_totals.ios }}</td>
          <td>{{ stats_data.payment_user_totals.android }}</td><td>
            系统内订单收入，不包含后台开通会员、终身会员。
          </td>
        </tr>
        <tr>
          <td >付费率</td>
          <td>{{ (100 * stats_data.payment_user_totals.ios / stats_data.user_totals.ios).toFixed(2) }}%</td>
          <td>{{ (100 * stats_data.payment_user_totals.android / stats_data.user_totals.android).toFixed(2) }}%</td>
          <td>
          </td>
        </tr>
        <tr>
          <td >人均付费金额</td><td>
            {{ (stats_data.payment_sums.ios.pay_amount / 100 / stats_data.payment_user_totals.ios).toFixed(2) }}¥
          </td>
          <td> 
            {{ (stats_data.payment_sums.android.pay_amount / 100  / stats_data.payment_user_totals.android).toFixed(2) }}¥
          </td>
          <td>
            系统内订单收入，不包含后台开通会员、终身会员。
          </td>
        </tr>
        
        
      </table>
      <div>
        *有误差，20240827之前注册的历史用户分端类型统计不完整
      </div>
      
      <hr />
      <table class="stats-table">
        <legend>昨日整体数据</legend>
        <tr>
          <th >项目</th><th>数据</th><th>公式</th><th>说明</th>
        </tr>
        <tr>
          <td>用户新增</td><td>{{ stats_data.user_today_count }}</td><td></td><td></td>
        </tr>
        <tr>
          <td>会员新增：</td><td>{{ stats_data.vip_today_count }}</td><td></td><td>
            包括应用内购买会员、后台开通会员、终身会员等所有会员
          </td>
        </tr>
        <tr>
          <td>订单收入</td><td> {{ stats_data.pay_today_amount.pay_amount / 100 }}¥</td><td></td>
          <td>系统内订单收入，不包含后台开通会员、终身会员</td>
        </tr>
        <tr>
          <td>新用户付费金额</td>
          <td> {{ (stats_data.pay_user_today_amount.pay_amount / 100 ).toFixed(2) }}¥ </td>
          <td></td>
          <td>系统内订单收入，不包含后台开通会员、终身会员</td>
        </tr>
        <tr>
          <td>新用户人均付费金额</td>
          <td> {{ (stats_data.pay_user_today_amount.pay_amount / 100 / stats_data.user_today_count ).toFixed(2) }}¥ </td>
          <td>{{ stats_data.pay_user_today_amount.pay_amount / 100 }} /  {{stats_data.user_today_count }}</td>
          <td>系统内订单收入，不包含后台开通会员、终身会员</td>
        </tr>
        <tr>
          <td>人均付费金额</td>
          <td> {{ (stats_data.pay_today_amount.pay_amount / 100 / stats_data.user_today_count ).toFixed(2) }}¥ </td>
          <td>{{stats_data.pay_today_amount.pay_amount / 100 }} / {{stats_data.user_today_count}}</td>
          <td>系统内订单收入，不包含后台开通会员、终身会员</td>
        </tr>
        <tr>
          <td>付费用户数</td>
          <td> {{ stats_data.pay_user_today_count }} </td>
          <td></td>
          <td>系统内订单收入，不包含后台开通会员、终身会员</td>
        </tr>
        <tr>
          <td>昨日订单信息</td>
          <td> Apple {{ stats_data.new_order_sum.apple.pay_amount / 100 }}¥   (count: {{ stats_data.new_order_sum.apple.total_count }})<br/>
          Alipay  {{ stats_data.new_order_sum.alipay.pay_amount / 100 }}¥  (count: {{ stats_data.new_order_sum.alipay.total_count }})</td>
          <td></td>
          <td>系统内订单收入，不包含后台开通会员、终身会员</td>
        </tr>
      </table>
      <hr />
     
      <table class="stats-table">
        <legend>昨日分端数据 {{ today_dt }}</legend>
        <tr>
          <th >项目</th><th>iOS</th><th>Android</th><th>说明</th>
        </tr>
        <tr>
          <td >昨日新增用户</td><td>
            {{ stats_data.new_user_count.ios  }}
          </td><td>
            {{ stats_data.new_user_count.android  }}
          </td>
          <td> </td>
        </tr>
        <tr>
          <td >昨日新增用户会员开通</td><td>
            {{ stats_data.new_user_vip_count.ios  }}
            （开通率：{{ (stats_data.new_user_vip_count.ios / stats_data.new_user_count.ios * 100).toFixed(2) }}%）
          </td><td>
            {{ stats_data.new_user_vip_count.android  }}
            （开通率：{{ (stats_data.new_user_vip_count.android / stats_data.new_user_count.android * 100).toFixed(2) }}%）
          </td>
          <td>
            包括应用内购买会员、后台开通会员、终身会员等所有会员
          </td>
        </tr>
        <tr>
          <td >昨日会员开通</td><td>
            {{ stats_data.new_vip_count.ios  }}
           
          </td><td>
            {{ stats_data.new_vip_count.android  }}
          </td>
          <td>
            包括应用内购买会员、后台开通会员、终身会员等所有会员
          </td>
        </tr>
        <tr>
          <td >昨日新增用户付费金额</td><td>
            {{ (stats_data.new_user_payment_amount.ios / 100).toFixed(2)  }}¥
            （人均付费金额： {{ (stats_data.new_user_payment_amount.ios / 100 / stats_data.new_user_count.ios).toFixed(2) }}¥）
          </td><td>
            {{ (stats_data.new_user_payment_amount.android / 100).toFixed(2)  }}¥
            （人均付费金额： {{ (stats_data.new_user_payment_amount.android / 100 / stats_data.new_user_count.android).toFixed(2) }}¥）
          </td>
          <td>
            系统内订单收入，不包含后台开通会员、终身会员
          </td>
        </tr>
        <tr>
          <td >7日内新增用户数</td><td>
            {{stats_data.new_user_7d_count.ios  }}
          </td><td>
            {{stats_data.new_user_7d_count.android  }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td >7日内新增用户付费数</td><td>
            {{stats_data.new_user_payment_7d_count.ios  }}
            (付费率: {{ (stats_data.new_user_payment_7d_count.ios / stats_data.new_user_7d_count.ios * 100).toFixed(2) }}%)
          </td><td>
            {{stats_data.new_user_payment_7d_count.android  }}
            (付费率: {{ (stats_data.new_user_payment_7d_count.android / stats_data.new_user_7d_count.android * 100).toFixed(2) }}%)
          </td>
          <td>
            系统内订单收入，不包含后台开通会员、终身会员
          </td>
        </tr>
        <tr>
          <td >30日内新增用户数</td><td>
            {{stats_data.new_user_30d_count.ios  }}
          </td><td>
            {{stats_data.new_user_30d_count.android  }}
          </td>
          <td></td>
        </tr>
        <tr>
          <td >30日内新增用户付费数</td><td>
            {{stats_data.new_user_payment_30d_count.ios  }}
            (付费率: {{ (stats_data.new_user_payment_30d_count.ios / stats_data.new_user_30d_count.ios * 100).toFixed(2) }}%)
          </td><td>
            {{stats_data.new_user_payment_30d_count.android  }}
            (付费率: {{ (stats_data.new_user_payment_30d_count.android / stats_data.new_user_30d_count.android * 100).toFixed(2) }}%)
          </td>
          <td>
            系统内订单收入，不包含后台开通会员、终身会员
          </td>
        </tr>
      </table>
      <hr/>
      
     
    
      <table class="stats-table">
        <legend>昨日作品相关数据</legend>
        <tr>
          <th >项目</th><th>数据</th><th>公式</th><th>说明</th>
        </tr>
        <tr>
          <td>新用户使用次数 0</td><td> {{ stats_data.new_user_generate_counts["0"] }} 
            （占比: {{ (stats_data.new_user_generate_counts["0"] * 100 / stats_data.user_today_count).toFixed(2) }}%）
          </td>
          <td></td><td></td>
        </tr>
        <tr>
          <td>新用户使用次数 1</td><td> {{ stats_data.new_user_generate_counts["1"] }} 
            （占比: {{ (stats_data.new_user_generate_counts["1"] * 100 / stats_data.user_today_count).toFixed(2) }}%）
          </td><td></td><td></td>
        </tr>
        <tr>
          <td>新用户使用次数 2</td><td> {{ stats_data.new_user_generate_counts["2"] }} 
            （占比: {{ (stats_data.new_user_generate_counts["2"] * 100 / stats_data.user_today_count).toFixed(2) }}%）
          </td>
          <td></td><td></td>
        </tr>
        <tr>
          <td>新用户使用次数 3</td><td> {{ stats_data.new_user_generate_counts["3"] }} 
            （占比: {{ (stats_data.new_user_generate_counts["3"] * 100 / stats_data.user_today_count).toFixed(2) }}%）
          </td>
          <td></td><td></td>
        </tr>
        <tr>
          <td>新用户使用次数 4</td><td> {{ stats_data.new_user_generate_counts["4"] }} 
            （占比: {{ (stats_data.new_user_generate_counts["4"] * 100 / stats_data.user_today_count).toFixed(2) }}%）
          </td>
          <td></td><td></td>
        </tr>
        <tr>
          <td>新用户使用次数 5</td><td> {{ stats_data.new_user_generate_counts["5"] }} 
            （占比: {{ (stats_data.new_user_generate_counts["5"] * 100 / stats_data.user_today_count).toFixed(2) }}%）
          </td>
          <td></td><td></td>
        </tr>
        <tr>
          <td>新用户使用次数 6</td><td> {{ stats_data.new_user_generate_counts["6"] }} 
            （占比: {{ (stats_data.new_user_generate_counts["6"] * 100 / stats_data.user_today_count).toFixed(2) }}%）
          </td>
          <td></td><td></td>
        </tr>
        <tr>
          <td>新用户使用次数 &gt; 6</td><td> {{ stats_data.new_user_generate_counts[">6"] }} 
            （占比: {{ (stats_data.new_user_generate_counts[">6"] * 100 / stats_data.user_today_count).toFixed(2) }}%）
          </td>
          <td></td><td>基数为当日新增</td>
        </tr>
        <tr>
          <td>创作人数</td><td> {{ stats_data.generate_counts["uv"] }} </td><td></td><td>包括创作失败的情况</td>
        </tr>
        <tr>
          <td>创作数量</td><td> {{ stats_data.generate_counts["total"] }} 
            (人均创作数量: {{ (stats_data.generate_counts["total"] / stats_data.generate_counts["uv"]).toFixed(2) }} )
          </td>
          <td></td>
          <td>包括创作失败的情况</td>
        </tr>
        <tr>
          <td>创作失败数量</td><td>
            {{ stats_data.generate_counts["total"] - stats_data.generate_counts["success"] }} 
            (失败率:  {{ ((stats_data.generate_counts["total"] - stats_data.generate_counts["success"]) * 100 / stats_data.generate_counts["total"]).toFixed(2) }}% )
          </td><td>
            {{  stats_data.generate_counts["total"] - stats_data.generate_counts["success"] }} / {{ stats_data.generate_counts["total"] }} 
          </td><td></td>
        </tr>
        <tr>
          <td>创作离开数量</td><td>
            {{ stats_data.generate_counts["leave"] }} 
            (离开率:  {{ ((stats_data.generate_counts["leave"] ) * 100 / stats_data.generate_counts["total"]).toFixed(2) }}% )
          </td><td>
            {{  stats_data.generate_counts["leave"] }} / {{ stats_data.generate_counts["total"] }} 
          </td><td></td>
        </tr>
        <!-- <tr>
          <td>终身会员创作量</td><td>
            {{ stats_data.life_user["new_generate_count"] }} 
            (人均创作:  {{ ((stats_data.life_user["new_generate_count"] ) * 100 / stats_data.life_user["count"]).toFixed(2) }}% )
          </td><td>
            {{  stats_data.life_user["new_generate_count"] }} / {{ stats_data.life_user["count"] }} 
          </td><td></td>
        </tr> -->
        <tr>
          <td>生成作品数量</td><td>
            {{ stats_data.download_counts["total"] }} 
          </td>
          <td></td>
          <td>不包含失败的任务</td>
        </tr>
        <tr>
          <td>下载图片数量</td><td>
            {{ stats_data.download_counts["download_count"] }} 
            (下载率:  {{ ((stats_data.download_counts["download_count"] ) * 100 / stats_data.download_counts["total"] ).toFixed(2) }}% )
          </td><td>
            {{ stats_data.download_counts["download_count"] }} / {{  stats_data.download_counts["total"]  }}
          </td><td></td>
        </tr>
      </table>
    
    </a-watermark>
  </a-card>

  <!-- <UserEditor v-model:visible="userEditorVisible" @cancle="userEditorVisible=false" /> -->

</template>
<script>
import { defineComponent,  onMounted} from 'vue'
// import UserEditor from '@/components/UserEditor.vue'
// import { SearchOutlined } from '@ant-design/icons-vue';
import axios from 'axios'
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'
const TaskStatus = {
  0: "排队中",
  1: "创作中",
  2: "创作完成",
  3: "创作失败",
}

export default defineComponent({
  components: {
    // UserEditor,
    // SearchOutlined
  },
  data(){
    const today = new Date();
    today.setDate(today.getDate() - 1);
    return {
      today_dt: today.toLocaleDateString(),
      TaskStatus,
      loading: false,
      dataSource: [],
      pagination: {
        total: 1,
        current: 1,
        pageSize: 20,
        'show-size-changer': true,
        pageSizeOptions: ['20', '50', '100']
      },
      noticeMessage: '',
      stats_data:{
        "life_user": {
          "count":0,
          "new_generate_count": 0,
        },
        "new_order_sum": {
          "alipay": {},
          "apple": {},
        },
        "total_order_sum": {
          "alipay": {},
          "apple": {},
        },
        "download_counts": {},
        "generate_counts": {},
        "new_user_generate_counts": {},
        "new_user_30d_count": {
          ios: 0,
          android: 0
        },
        "new_user_7d_count": {
          ios: 0,
          android: 0
        },
        "new_user_payment_30d_count": {
          ios: 0,
          android: 0
        },
        "new_user_payment_7d_count": {
          ios: 0,
          android: 0
        },
        "new_user_count": {
          ios: 0,
          android: 0
        },
        "new_user_vip_count": {
          ios: 0,
          android: 0
        },
        "new_vip_count": {
          ios: 0,
          android: 0
        },
        "new_user_payment_amount": {
          ios: 0,
          android: 0
        },
        "user_totals": {
          ios:{}, android:{}
        },
        "payment_sums": {
          ios:{}, android:{}
        },
        "vip_expire_count": 0,
        "renew_order_count": 0,
        "payment_user_totals": {
          ios:{}, android:{}
        },
        "pay_today_amount": {
            "pay_amount": 0,
            "total_amount": 0,
            "discount_amount": 0
        },
        "pay_total_amount": {
            "pay_amount": 0,
            "total_amount": 0,
            "discount_amount": 0
        },
        "pay_user_7day_amount": {
            "pay_amount": 0,
            "total_amount": 0,
            "discount_amount": 0
        },
        "pay_user_7day_count": 0,
        "pay_user_30day_count": 0,
        "pay_user_today_amount": {
            "pay_amount": 0,
            "total_amount": 0,
            "discount_amount": 0
        },
        "pay_user_today_count": 0,
        "pay_user_total_count": 0,
        "user_7day_count": 0,
        "user_30day_count": 0,
        "user_today_count": 0,
        "user_total_count": 0,
        "vip_today_count": 0,
        "vip_total_count": 0
      },
       formSearch: {
        user_id: '',
        nickname: '',
        mobile: '',
      },

      uploadLoading: false,
    }
  },
  mounted(){
    this.load()
  },
  computed:{
    user_id : () => {
      const store = useStore()
      if (store) {
        return store.state.user.user_id
      }
      
    },
  },
  methods: {
    async load () {
      this.loading = true
      const rsp = await request.get('/api/users/stats', {})
      this.loading = false
      for(let k in rsp.data) {
        this.stats_data[k] = rsp.data[k]
      }
      const rspv2 = await request.get('/api/users/statsv2', {})
      this.loading = false
      for(let k in rspv2.data) {
        this.stats_data[k] = rspv2.data[k]
      }
    },
  
  },
  setup(props, context) {
   
    return {
      uploadInput: null,
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}

.stats-table {
  
}
.stats-table td {
  border: 1px solid #999;
  padding: 4px 8px;
}
</style>