<template>
  <a-card title="VIP 管理">
    <a-space >
      <a-form layout="inline" :model="formSearch" style="margin: 10px auto;" >
        <a-form-item>
          <a-input placeholder="用户 ID" v-model:value="formSearch.query" />
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            @click="handleTableChange"
            shape="circle"
          >
          <template #icon><SearchOutlined /></template>
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            @click="openDia = !openDia"
          >
          赠与会员
          </a-button>
        </a-form-item>
      </a-form>
    </a-space>

    <a-alert :message="noticeMessage" type="info" show-icon style="text-align: left;"  />
    <a-watermark :content="nickname">
      <a-table 
      class="ant-table-striped"
      :row-key="record => record.id"
      :data-source="dataSource" 
      :columns="columns" 
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
        <template #nickname="{ record }">
          <span>{{ record.nickname ? record.nickname : '-' }}</span>
        </template>
        <template #phone="{ record }">
          <a-tooltip>
            <template #title>{{ record.phone }}</template>
            {{ record.phone ? "*******" + record.phone.substr(record.phone.length -4, 4) : '-' }}
          </a-tooltip>
        </template>
        <template #is_vip="{ record }">
          <a-tag v-if="record.is_vip" color="red">VIP</a-tag>
        </template>
    
      </a-table>
    </a-watermark>
  </a-card>
  <a-modal v-model:visible="openDia" :title="'授予 VIP'" @cancel="resetForm" @ok="handleConfirm" :confirm-loading="confirmLoading">
    <a-alert message="*手机、用户 ID 二选一" type="warning"></a-alert>
    <a-form :model="formAdd" style="margin: 10px auto;" :rules="rules" ref="formRef" >

       <a-form-item label="区号" name="country_code">
          <a-input placeholder="区号" v-model:value="formAdd.country_code" />
        </a-form-item>
        <a-form-item label="手机号" name="phone">
          <a-input placeholder="手机号" v-model:value="formAdd.phone" />
        </a-form-item>
        <a-form-item label="UserID" name="user_id">
          <a-input placeholder="用户 ID" v-model:value="formAdd.user_id" />
        </a-form-item>
        <a-form-item  label="产品 ID"  name="product_id">
          <a-select
            v-model:value="formAdd.product_id"
            :options="productOptions"
            placeholder="产品 ID"
          />
        </a-form-item>
      </a-form>
  </a-modal>
  <!-- <UserEditor v-model:visible="userEditorVisible" @cancle="userEditorVisible=false" /> -->

</template>
<script>
import { defineComponent,  onMounted} from 'vue'
// import UserEditor from '@/components/UserEditor.vue'
import { SearchOutlined } from '@ant-design/icons-vue';
import axios from 'axios'
import { message } from 'ant-design-vue';
import request from '@/utils/request'
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex'


export default defineComponent({
  components: {
    // UserEditor,
    SearchOutlined
  },
  data(){
    return {
      productOptions: [
        {
          label:"1天",
          value: "1",
        },
        {
          label:"2天",
          value: "2",
        },
        {
          label:"3天",
          value: "3",
        },
        {
          label:"4天",
          value: "4",
        },
        {
          label:"5天",
          value: "5",
        },
        {
          label:"6天",
          value: "6",
        },
        {
          label:"7天",
          value: "7",
        },
        {
          label:"10天",
          value: "10",
        },
        {
          label:"1个月",
          value: "1month",
        },
        {
          label:"6个月",
          value: "6month",
        },
        {
          label:"12个月",
          value: "12month",
        }, {
          label:"终身",
          value: "18250", // 50 * 365
        }
      ],
      loading: false,
      openDia: false,
      dataSource: [],
      formAdd: {
        phone: '',
        country_code: "+86",
        user_id: '',
        product_id: '1'
      },
      pagination: {
        total: 1,
        current: 0,
        pageSize: 20,
        'show-size-changer': true,
        pageSizeOptions: ['20', '50', '100']
      },
      noticeMessage: '',

       formSearch: {
        query: ''
      },

      uploadLoading: false,
    }
  },
  mounted(){
    this.handleTableChange()
  },
  computed:{
    nickname : () => {
      const store = useStore()
      if (store) {
        return store.state.user.nickname
      }
      
    },
  },
  methods: {
    async handleConfirm(){
      const rsp = await request.post('/api/vips/grant', this.formAdd)
      this.openDia = !this.openDia
      this.loading = false
    },
    resetForm() {
      this.$refs.formRef.resetFields();
    },
    async handleTableChange (pagi = {pageSize: 20, current: 1}) {
      this.loading = true
      const rsp = await request.get('/api/vips/', {
        offset: (pagi.current - 1) * pagi.pageSize ,
        limit: pagi.pageSize,
        user_id: this.formSearch.query
      })
      this.loading = false
      this.dataSource = [].concat(rsp.data.list)
      this.noticeMessage = `共 ${rsp.data.pagination.total} 条`
      this.pagination.total = rsp.data.pagination.total
      this.pagination.current = pagi.current
      this.pagination.pageSize = pagi.pageSize
    },
  },
  setup(props, context) {
   
    return {
      uploadInput: null,
      columns: [
      {
          title: 'UID',
          dataIndex: 'user_id',
          key: 'user_id',
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          key: 'nickname',
          slots: { customRender: 'nickname' },
        },
        {
          title: '订阅 ID',
          dataIndex: 'vip_meal_id',
          key: 'vip_meal_id',
        },
        {
          title: '订阅名称',
          dataIndex: 'vip_meal_name',
          key: 'vip_meal_name',
        },
        {
          title: '付费',
          dataIndex: 'pay_amount',
          key: 'pay_amount',
          // slots: { customRender: 'is_vip' },
        },
        {
          title: '过期时间',
          dataIndex: 'expired_at',
          key: 'expired_at',
          // slots: { customRender: 'is_vip' },
        },
        {
          title: '是否订阅续期',
          dataIndex: 'is_renewal',
          key: 'is_renewal',
          // slots: { customRender: 'is_vip' },
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          key: 'created_at',
        },
        {
          title: '修改时间',
          dataIndex: 'updated_at',
          key: 'updated_at',
        },
        
       
      ],
    };
  },
})
</script>

<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
}
</style>