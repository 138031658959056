<template>
  <a-layout style="height:100%">
    <a-layout-header>
      <a-row  style="color: #FFF;display:flex;flex-direction:row;justify-content:space-between;">
        <div style="color: #FFF; font-size: 20px;font-weight: bold; ">Xuantu Admin</div>
        <div>
          <span style="font-size: 14px;">当前用户: {{nickname}}</span>
          <a-button type="link" @click="handleLogout">退出</a-button>
        </div>
      </a-row>
    </a-layout-header>

    <a-layout style="height:calc( 100% - 64px );overflow: hidden;">
      <a-layout-sider   v-model:collapsed="collapsed" collapsible style="overflow-y:auto;height:100%">
          <Navi />
      </a-layout-sider>
      <a-layout-content style="overflow-y:auto">
        <a-layout-content style="min-height:calc( 100% - 80px );">
          <router-view />
        </a-layout-content>
          <a-layout-footer>
            <div style="text-align: center;">
              2024©copyright Xuantu
            </div>
          </a-layout-footer>
      </a-layout-content>
    </a-layout>

  </a-layout>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Navi from '../components/Navi.vue';
import {useStore} from 'vuex'
import {reactive} from 'vue'
import request from '../utils/request'
import {UserRoleMap} from '@/utils/macro.js'
@Options({
  components: {
      Navi
  },
  props: {
    msg: String
  },
  data(){
    const store = useStore()
     return reactive({
      collapsed: false,
      user_id: store.state.user.user_id,
    })
  },
  computed:{
    nickname: () => {
      const store = useStore()
      return store.state.user.nickname
    }
  },
  methods: {
    handleLogout(){
        request.get(`/api/account/logout`).then(() => {
          location.href="/"
        })
      }
  },
  setup(){
   
    return {
      UserRoleMap
    }
  }
})

export default class BasicLayout extends Vue {
  msg!: string
}
</script>

<style scoped>

</style>